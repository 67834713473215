import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import colors from "../utils/colors";
import hamburger from "../images/icons/hamburger.svg";
import close from "../images/icons/close.svg";
import { windowSize } from "../utils/responsive";
import StockperksLogoSvg from "../images/stockperks.svg";


const Nav = ({ siteTitle }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <NavContainer isMenuOpen={isMenuOpen}>
      <NavBrand>
        <Link to="/">
          <StockperksLogo
            src={StockperksLogoSvg}
            alt={siteTitle}
          />
        </Link>
      </NavBrand>
      <NavContent>
        <NavRightContent>
          <NavList>
            <NavItem>
              <NavLink activeStyle={NavLinkActive} to="/contact">
                Contact
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                activeStyle={NavLinkActive}
                to="/blog"
                partiallyActive={true}
              >
                News & Blog
              </NavLink>
            </NavItem>
          </NavList>
        </NavRightContent>
      </NavContent>
      <NavHamburger
        icon={isMenuOpen ? close : hamburger}
        onClick={handleToggleMenu}
      />
    </NavContainer>
  );
};

Nav.propTypes = {
  siteTitle: PropTypes.string
};

Nav.defaultProps = {
  siteTitle: ""
};

export default Nav;

const NavContainer = styled.nav`
  background: ${colors.cobalt};
  display: flex;
  flex-direction: row;
  height: 65px;
  left: 0;
  overflow: hidden;
  padding: 0 30px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;

  @media (max-width: ${windowSize.medium}px) {
    display: block;
    ${props => props.isMenuOpen && "height: auto;"}
  }
`;

const NavBrand = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
`;

const NavContent = styled.div`
  justify-content: flex-end;
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 65px;

  @media (max-width: ${windowSize.medium}px) {
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 0;
  }
`;

const NavRightContent = styled.div`
  display: flex;
`;

const NavList = styled.ul`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 0px;
  padding: 0;

  @media (max-width: ${windowSize.medium}px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const NavItem = styled.li`
  list-style-type: none;
  margin-right: 30px;
  color: ${colors.offWhite};
  @media (max-width: ${windowSize.medium}px) {
    margin-bottom: 10px;
  }
`;

// Gatsby Link component requires the use of the activeStyle prop for styling
// the current link. See documentation: https://www.gatsbyjs.org/docs/gatsby-link/
const NavLinkActive = {
  color: colors.offWhite,
  opacity: 1
};

const NavLink = styled(Link)`
  color: ${colors.offWhite};
  opacity: 0.5;
`;

const NavHamburger = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  right: 25px;
  top: 18px;

  &:before {
    content: url(${props => props.icon});
  }

  @media (min-width: ${windowSize.medium + 1}px) {
    display: none;
  }
`;

const StockperksLogo = styled.img`
  width: 140px;
  padding-top: 10px;
`
